// Import theme Functions/Variables
@import "variables";
@import "functions";

// Import Bootstrap Functions/Variables
@import "../../vendor/twbs/bootstrap/scss/functions";
@import "../../vendor/twbs/bootstrap/scss/variables";

// Import Bootstrap Styles
@import "../../vendor/twbs/bootstrap/scss/vendor/rfs";
@import "../../vendor/twbs/bootstrap/scss/mixins/hover";
@import "../../vendor/twbs/bootstrap/scss/mixins/grid";
@import "../../vendor/twbs/bootstrap/scss/mixins/grid-framework";
@import "../../vendor/twbs/bootstrap/scss/mixins/breakpoints";
@import "../../vendor/twbs/bootstrap/scss/mixins/border-radius";
@import "../../vendor/twbs/bootstrap/scss/mixins/box-shadow";
@import "../../vendor/twbs/bootstrap/scss/mixins/transition";
@import "../../vendor/twbs/bootstrap/scss/mixins/deprecate";
@import "../../vendor/twbs/bootstrap/scss/mixins/forms";
@import "../../vendor/twbs/bootstrap/scss/mixins/gradients";
@import "../../vendor/twbs/bootstrap/scss/mixins/alert";
//@import "../../vendor/twbs/bootstrap/scss/mixins/badge"; // incompatible with php css.php (LEAFO SCSS)
@import "../../vendor/twbs/bootstrap/scss/reboot";
@import "../../vendor/twbs/bootstrap/scss/grid";
@import "../../vendor/twbs/bootstrap/scss/forms";
@import "../../vendor/twbs/bootstrap/scss/custom-forms";
@import "../../vendor/twbs/bootstrap/scss/alert";
//@import "../../vendor/twbs/bootstrap/scss/badge";

// Import theme styles
@import "mixins";
@import "parts-login";
@import "admin_main";
