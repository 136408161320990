$green: #5b8334;
$purple: #7d4c98;
$blue: #086eb6;
$yellow: #dbc600;
$red: #C11717;
$grey: #bfbebf;

// Brand colours
$brand-colours: (
  primary-1: $green,
  primary-2: $purple,
  primary-3: $blue,
  primary-4: $yellow,
  primary-5: $red,
  primary-6: $grey
);

// User state colours
$user-colours: (
	active: $green,
	away: $yellow,
	gone: $red
);

//Override Bootstrap 4 variables
$theme-colors: (
	primary: $green,
	secondary: $purple,
	success: $green,
	info: $blue,
	warning: $yellow,
	danger: $red,
	disabled: $grey
);

$grid-gutter-width: 30px;

// Text colours
$font-colours: (
  body    : #2f2e2f,
  heading : #2f2e2f
);

// List of colours for looping
$loop-colours: map_merge( $brand-colours, $font-colours );

// Neutrals
$neutral-colours: (
  light : #f1f2f7
);

// Default font
$font: 'Roboto';
$font-size-base: 0.875rem;
$font-size-body: 1rem;

// Height of header
$header-height: 80px;

// Height of titlebar
$titlebar-height: 70px;

// Width of sidebar
$sidebar-width: 14rem;

// Height of input items
$input-height: 40px;

// Height of buttons
$button-height: 40px;
$button-height-sm: 30px;
$button-height-tiny: 20px;
$icon-button-size: 20px;

// Height of menu items
$menu-item-height: 55px;
$menu-item-height-sm: 30px;

$displays: none, flex, block ;


