html {
	font-size: $font-size-base;
}

body {
	font-family: $font, sans-serif;
	font-size: $font-size-body;
}

a {
	transition: all 0.15s ease-in-out;
	text-decoration: none !important;
	.content & {
		font-weight: 500;
	}
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
	font-weight: normal;
}

h1,.h1 {
	font-size: 1.6rem;
}

h2,.h2 {
	font-size: 1.4rem;
}

h3,.h3 {
	font-size: 1.2rem;
}

@each $name, $colour in $loop-colours {
	&.colour-#{$name} {
		color: $colour;
	}
}

.sub-text {
	opacity: 0.6;
}

.small {
	font-size: 0.85rem !important;
}

.nowrap {
	white-space: nowrap;
}
