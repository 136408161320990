body {
  background-color: colour(light);
}

.text-white{
	color: white;
}

.color-card{
	box-shadow: 0 0 10px 0 #4e4e4e;
	margin: 0 5px 30px;
	padding: 0;


	& .count_top{
		width: 100%;
		display: block;
		text-align: center;
		background-color: #06060636;
		padding: 5px;
	}
	& .count{
		text-align: center	;
		font-size: 20px;
		line-height: 47px;
		font-weight: 600;
		//padding: 20px;
	}
}

.table-tab{
	display: inline-block;
	padding: 5px 5px 2px;
	margin: 0;
	color: white;
	box-shadow: 0 0 10px 0 #aba9a9;
	& + table{
		box-shadow: 0 0 10px 0 #aba9a9;
	}
}


