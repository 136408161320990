form {
	width: 100%;
}

.form-control {
	height: $input-height;
	color: colour(heading);
	font-size: 0.9rem;
	border-color: colour(light);
	border-width: 2px;
	.rounded & {
		border-radius: $input-height / 2;
		padding-left: $input-height / 2;
		padding-right: $input-height / 2;
	}
	&.form-control-light {
		border-color: #fff;
	}
}

label {
	margin-bottom: 0.25rem;
}

legend.required,
label.required:not(.form-check-label) {
	&:after{
		content: "*";
		color: red;
		margin-left: 0.25rem;
	}

	> span.invalid-feedback &:after{
		display: none;
	}
}

input.is-invalid {
	+ span.invalid-feedback {
		margin: 0 0 1rem;
	}
}

fieldset {
	.form-group:last-of-type {
		margin-bottom: 0;
	}
}

legend {
	color: colour(primary-1);
}

// Change input placeholder colour
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: colour(heading) !important;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: colour(heading) !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
	color: colour(heading) !important;
}

.submit-hidden {
	*[type="submit"] {
		position: absolute;
		left: -9999px;
	}
}

.button-row {
	display: flex;
	padding: $grid-gutter-width / 2 0;
	*[type="submit"] {
		margin-left: auto;
	}
}

.form-group.multiple .form-check{
	display: block;
	margin-left: 2rem;
}

.form-check {
	position: relative;
	display: inline-block;
	padding-left: 0;
	&:not(:last-of-type) {
		margin-right: 20px;
	}
	input {
		position: absolute;
		z-index: -1;
		opacity: 0;
		+ label {
			padding-left: 25px;
			cursor: pointer;
			&:before, &:after {
				content: "";
				display: block;
				width: 18px;
				height: 18px;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				transition: all 0.15s ease-in-out;
				cursor: pointer;
			}
			&:before {
				background-color: colour(light);
				border: 2px solid $input-focus-border-color;
			}
			&:after {
				background-color: $input-focus-border-color;
				transform: translateY(-50%) scale(0);
				border: 5px solid darken( colour( primary-1 ), 20% );
			}
		}
		&[type="radio"] {
			+ label {
				&:before, &:after {
					border-radius: 50%;
				}
			}
		}
		&:focus {
			+ label {
				&:before {
					// Avoid using mixin so we can pass custom focus shadow properly
					box-shadow: 0 0 0 1px #fff, $input-focus-box-shadow;
				}
			}
		}
		&:checked {
			+ label {
				&:after {
					transform: translateY(-50%) scale(1);
					border: 0;
				}
			}
		}
	}
}

.form-group.multiple .form-check{
	border-bottom: 1px solid grey;
}
.form-group.multiple .form-check-label {
	padding:0.75rem 0 0.75rem 2.5rem;
}

.custom-file {
	.custom-file-label {
		border: 2px solid colour(light);
		&::after {
			height: $input-height - 4px;
			line-height: $input-height - 4px;
			padding-top: 0;
			padding-bottom: 0;
			background-color: colour(light);
		}
	}
}
