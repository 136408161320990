.login {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: $grid-gutter-width;
	background-color: colour(light);
	background-image: url("/login-bg.jpg");
	@include background-size();
	background-attachment: fixed;
	.overlay {
		@include absolute();
		background: linear-gradient(180deg,rgba(125,75,152,.93) 0%,rgba(73,108,220,.7) 100%);
	}
}
.login-box {
	width: 100%;
	max-width: 400px;
	@include box-shadow();
	background-color: #fff;
	position: relative;
	z-index: 2;
	header {
		padding: $grid-gutter-width / 2;
		background-color: colour(light);
		img {
			height: 80px;
			width: auto;
			display: block;
			margin: 0 auto;
		}
	}
	.login-body {
		padding: $grid-gutter-width;
	}
}
