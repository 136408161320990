@mixin button-colour( $bg ) {
	background-color: $bg;
	color: #fff;
	&:hover {
		background-color: darken( $bg, 10% );
	}
	&:focus {
		background-color: darken( $bg, 12.5% );
	}
	&:active {
		box-shadow: 0 0 0 0.2rem rgba( $bg, 0.4 );
	}
}
@mixin icon-button-colour( $colour ) {
	svg {
		path, rect {
			fill: $colour;
		}
	}
	&:hover {
		svg {
			path, rect {
				fill: darken( $colour, 10% );
			}
		}
	}
	&:focus {
		svg {
			path, rect {
				fill: darken( $colour, 12.5% );
			}
		}
	}
}
