.offset-header {
  padding-top: $header-height;
}

.box-shadow {
  @include box-shadow();
}

// Vertical padding
.vp {
	padding-top: $grid-gutter-width;
	padding-bottom: $grid-gutter-width;
	&-h {
		padding-top: $grid-gutter-width / 2;
		padding-bottom: $grid-gutter-width / 2;
	}
	&-q {
		padding-top: $grid-gutter-width / 4;
		padding-bottom: $grid-gutter-width / 4;
	}
}
// Horizontal padding
.hp {
	padding-left: $grid-gutter-width;
	padding-right: $grid-gutter-width;
	&-h {
		padding-left: $grid-gutter-width / 2;
		padding-right: $grid-gutter-width / 2;
	}
	&-q {
		padding-left: $grid-gutter-width / 4;
		padding-right: $grid-gutter-width / 4;
	}
	&-0 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.mb-15 {
	margin-bottom: $grid-gutter-width / 2;
}

.mt-15 {
	margin-top: $grid-gutter-width / 2;
}

.icon {
	width: 20px;
	height: auto;
	display: inline-block;
}

th.icon {
	display: table-cell;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.hide {
	display: none;
}

@each $name, $colour in $loop-colours {
	&.bg-#{$name} {
		background-color: $colour;
	}
}

.flex-row {
	> [class*="col"] {
		display: flex;
		min-width: 0;
	}
}
