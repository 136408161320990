.content-inner {
	padding: $grid-gutter-width / 2 $grid-gutter-width $grid-gutter-width;
}

.content-narrow {
	padding: $grid-gutter-width / 2 $grid-gutter-width / 2 $grid-gutter-width / 2;
}

.extend-content {
	margin-left: -$grid-gutter-width;
	margin-right: -$grid-gutter-width;
	padding-left: $grid-gutter-width;
	padding-right: $grid-gutter-width;
}

.extend-content-h {
	margin-left: -$grid-gutter-width;
	margin-right: -$grid-gutter-width;
	padding-left: $grid-gutter-width;
	padding-right: $grid-gutter-width;
}

.block {
	background-color: #fff;
	@include box-shadow();
	margin-bottom: $grid-gutter-width;
	word-break: break-word;
	.block-title {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		background-color: colour(light);
		min-height: 3rem;
		padding: 0 $grid-gutter-width / 2;
		h1, h2, h3, h4, h5, h6 {
			margin: 0 auto 0 0;
		}
	}
}

.row {
	&.half-spacing {
		margin-left: - $grid-gutter-width / 2;
		margin-right: - $grid-gutter-width / 2;
		[class^="col"] {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
		}
	}

	&.center {
		justify-content: center;
	}
}

.row-right {
	display: flex;
	justify-content: flex-end;
	padding: 1rem 0;
}

@media (max-width: 768px) {
	.content {
		padding-left: 0;
	}
}
