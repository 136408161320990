.alert {
	margin-bottom: $grid-gutter-width;
	border: 0;
	border-left: 5px solid;
	border-radius: 0;
	background-color: white;
	@include box-shadow();
	font-size: 1rem;
	&-info {
		color: colour(info);
	}
	> div:first-child {
		flex: 1;
	}
	.btn-group {
		padding-left: $grid-gutter-width / 2;
	}
}

section.content > .alert {
	margin: 1rem 0;
}
