.btn {
	display: inline-block;
	font-size: 0.9rem;
	height: $button-height;
	min-width: 140px;
	padding: 0 $button-height / 2;
	border: 0;
	outline: 0 !important;
	box-shadow: none;
	text-decoration: none !important;
	transition: all 0.15s ease-in-out;
	font-weight: bold;
	text-align: center;
	line-height: $button-height;
	border-radius: $button-height / 2;
	&.sq {
		border-radius: 0;
	}
	@each $name, $colour in $brand-colours {
		&.#{$name} {
			@include button-colour( $colour );
		}
	}
	@each $name, $colour in $theme-colors {
		&.#{$name} {
			@include button-colour( $colour );
		}
	}
	&.light {
		color: colour(body);
	}
	&.sm {
		height: $button-height-sm;
		line-height: $button-height-sm;
		min-width: 100px;
		svg {
			width: 15px;
			height: 15px;
		}
	}
	&.tiny,
	table & {
		height: $button-height-tiny;
		line-height: $button-height-tiny;
		min-width: 0;
		padding-left: $button-height-tiny / 2;
		padding-right: $button-height-tiny / 2;
		font-size: 0.8rem;
		font-weight: normal;
	}
	+ .btn {
		margin-left: 10px;
	}
	svg {
		width: 12px;
		height: 12px;
	}
	.input-group-append &,
	.input-group-prepend & {
		height: $input-height;
		min-width: 0;
	}

	&.btn-right {
		margin-left: auto;
	}
}

.btn-block {
	display: block;
	width: 100%;
	+ .btn-block {
		margin-top: $input-height;
	}
}

.icon-btn {
	display: inline-block;
	vertical-align: middle;
	width: $icon-button-size;
	height: $icon-button-size;
	svg {
		width: $icon-button-size;
		height: $icon-button-size;
		display: block;
		path {
			transition: all 0.15s ease-in-out;
		}
	}
	@each $name, $colour in $brand-colours {
		&.#{$name} {
			@include icon-button-colour($colour);
		}
	}
	@each $name, $colour in $theme-colors {
		&.#{$name} {
			@include icon-button-colour( $colour );
		}
	}
	+ .icon-btn {
		margin-left: 5px;
	}
}
