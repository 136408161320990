@media (min-width: 2000px) {
	body.container {
		max-width: 90%;
	}
}

#tasksOpenList > div.icon-details > div {
	width:100%;
	margin-bottom: 1rem;
}

.cal-date {
	position: relative;

	& > div {
		position: absolute;
		color: white;
	}
	& > div.date {
		top: 1.5rem;
		left: 1.25rem;
	}
	& > div.month {
		top: 2.5rem;
		left: 1rem;
	}
	& i.fa-5x + div{
		font-size: 0.75rem;
		top: 2.25rem;
		left: 0.25rem;
	}
}

table.fit th:first-child,
table.fit th:last-child,
table.fit td:first-child,
table.fit td:last-child {
	white-space: nowrap;
	width: 1%;
}

th.btn-col.btn-1 {
}

th.btn-col.btn-2 {
}

.btn-tiny {
	padding: 0.15rem 0.4rem;
	font-size: 0.75rem;
	line-height: 1.25;
	border-radius: 0.2rem;
}

.p0 {
	padding:0 !important;
}
.mt-05 {
	margin-top: 0.5rem;
}

.mt-1 {
	margin-top: 1rem;
}

.mt-2 {
	margin-top: 2rem;
}

.mb-05 {
	margin-bottom: 0.5rem;
}

.mb-1 {
	margin-bottom: 1rem;
}

.mb-2 {
	margin-bottom: 2rem;
}

.mx-0 {
	margin-left: 0;
	margin-right: 0;
}

.mx-05 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.mx-1 {
	margin-left: 1rem;
	margin-right: 1rem;
}

.mx-2 {
	margin-left: 2rem;
	margin-right: 2rem;
}

.my-0 {
	margin-top: 0;
	margin-bottom: 0;
}

.my-05 {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.my-1 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.my-2 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

#progress th {
	width:12.5%;
}

#quoteTableMin td:nth-child(3) {
	text-align: left;
}

.select2-container {
	display:block;
}

.tabulator {
	border:none !important;
	border-left: 1px solid #999 !important;
	background-color: transparent !important;
}
.tabulator-col{
	border-top: 1px solid #999;
}
.tabulator-header {
	border-bottom: none !important;
	background-color: transparent !important;
}
.tabulator-table {
	border-top: 1px solid #999;
	border-bottom: 1px solid #999;
}
