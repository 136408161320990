.btn-group {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	.btn {
		position: relative;
		flex: 0 1 auto;
		min-width: 0;
		&:not(:first-child) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		&:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		+ .btn {
			margin-left: 0;
		}
	}
	&-sm {
		.btn {
			height: $button-height-sm;
			line-height: $button-height-sm;
			padding-left: $button-height-sm / 2;
			padding-right: $button-height-sm / 2;
			font-size: 0.9rem;
		}
	}
	&-tiny {
		.btn {
			height: $button-height-tiny;
			line-height: $button-height-tiny;
			padding-left: $button-height-tiny / 2;
			padding-right: $button-height-tiny / 2;
			font-size: 0.8rem;
			font-weight: normal;
		}
	}
}
